import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';
import { HttpClient } from '@angular/common/http';
import { PhoneNumberModel } from '../models/phoneNumberModel';
import { EnvService } from './env.service';


@Injectable()
export class PhoneNumbersService extends BaseService {

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  getPhoneNumbers(): Observable<PhoneNumberModel[]> {
    return this
      .http
      .get<PhoneNumberModel[]>(this.ApiUrl + 'api/PhoneNumbers');
  }

  postPhoneNumber(phoneNumber: PhoneNumberModel): Observable<PhoneNumberModel> {
    return this
      .http
      .post<PhoneNumberModel>(this.ApiUrl + 'api/PhoneNumbers', phoneNumber);
  }

  putPhoneNumber(phoneNumber: PhoneNumberModel): Observable<PhoneNumberModel> {
    return this
      .http
      .put<PhoneNumberModel>(this.ApiUrl + 'api/PhoneNumbers/' + phoneNumber.id, phoneNumber);
  }

  deletePhoneNumber(id: number): Observable<any> {
    return this
      .http
      .delete(this.ApiUrl + 'api/PhoneNumbers/' + id);
  }
}
