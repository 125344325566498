import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {
  public selectedButtonState: number;
  public cnt: number;

  public convFilterTxt: string = '';
  public convFilterReadUnread: string = '';
  public convFilterMessageDate: string = '';
  public convFilterAgent: string = '';
  public convFilterEventName: string = '';

  constructor(){
    this.selectedButtonState = 0;
    this.cnt = 0;
  }

  setButtonStatet (bs) {
    this.selectedButtonState = bs;
  }
  getButtonState () {
    return this.selectedButtonState;
  }

  setCnt(cnt) {
    this.cnt = cnt;
  }
  getCnt() {
    return this.cnt;
  }
}
