import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';
import { HttpClient } from '@angular/common/http';
import { UploadExcelTemplateModel } from '../models/templateModels';
import { EnvService } from './env.service';


@Injectable()
export class UploadExcelService extends BaseService {

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  getUploadExcelTemplates(): Observable<UploadExcelTemplateModel[]> {
    return this
      .http
      .get<UploadExcelTemplateModel[]>(this.ApiUrl + 'api/UploadExcel/GetUploadExcelTemplates');
  }

  getUploadExcelTemplateTypes(): Observable<string[]> {
    return this
      .http
      .get<string[]>(this.ApiUrl + 'api/UploadExcel/GetUploadExcelTemplateTypes');
  }

  postTemplate(template: UploadExcelTemplateModel): Observable<UploadExcelTemplateModel> {
    return this
      .http
      .post<UploadExcelTemplateModel>(this.ApiUrl + 'api/UploadExcel/SaveUploadExcelTemplate', template);
  }
}
