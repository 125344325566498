import { Component } from '@angular/core';
import { MessageModel } from '../../models/messageModel';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewMessageModel } from '../../models/newMessageModel';
import { SnotifyService } from 'ng-snotify';
import { MessageQueueStatusesEnum, DirectionsEnum } from '../../models/enums';
import { HttpEventType } from '@angular/common/http';
import { MessagesService } from '../../services/MessagesService';
import { BaseComponent } from '../base/base.component';
import { ConversationModel } from '../../models/conversationModel';
import { ConversationsService } from '../../services/conversationsService';
import { ConfirmationDialogService } from '../dialogs/confirmation-dialog/confirmation-dialog.service';
import { AssignAgentDialogService } from '../dialogs/assign-agent-dialog/assign-agent-dialog.service';
import { AgentModel } from '../../models/agentModel';
import { AgentsService } from '../../services/agentsService';
import { AuthService } from '../../services/authService';
import { SharedService } from '../../services/sharedService';
import { UpdateConversationDialogService } from '../dialogs/update-conversation-dialog/update-conversation-dialog.service';
import { EnvService } from '../../services/env.service';

@Component({
  selector: 'conversation-thread-component',
  templateUrl: './conversation-thread.component.html',
  styleUrls: ['./conversation-thread.component.css']
})
export class ConversationThreadComponent extends BaseComponent {
  public selectedButtonState: number;
  public countConversations: number;

  public messages: MessageModel[];
  public sub: Subscription;
  public messageToSend: string = '';
  public conversationId: number = 0;
  public progress: number;
  private timeoutId: any;
  public conversation: ConversationModel;
  public searchTerm: string = '';
  public searchClicked: boolean = false;
  public isLoadMoreVisible: boolean = false;


  constructor(private messagesService: MessagesService,
    private conversationsService: ConversationsService,
    private route: ActivatedRoute,
    private router: Router,
    snotifyService: SnotifyService,
    private confirmationDialogService: ConfirmationDialogService,
    private assignAgentDialogService: AssignAgentDialogService,
    private sharedService: SharedService,
    private agentsService: AgentsService,
    public authService: AuthService,
    private updateConversationDialogService: UpdateConversationDialogService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.selectedButtonState = sharedService.selectedButtonState;
    this.countConversations = sharedService.cnt;
  }

  ngOnInit() {

    this.pageSize = 50;
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        //this.page = +params['page'] || 0;
        this.conversationId = parseInt(params['conversationId']);
        this.loadConversationBasicInfo(this.conversationId);
        this.loadMessages(this.conversationId);
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.destroyTimer();
  }

  public destroyTimer() {
    if (this.timeoutId) {
      console.log('Destroy timeout for messages');
      clearTimeout(this.timeoutId);
    }
  }

  nextPage() {
    //this.router.navigate(['product-list'], { queryParams: { page: this.page + 1 } });
  }

  public loadConversationBasicInfo(conversationId: number) {
    this.conversationsService.getBasicInfo(conversationId).subscribe(
      data => {
        this.conversation = data;
      },
      error => {
        this.showError('Error in loading info about conversation');
        this.showResponseError(error);
      });
  }
  public loadMessages(conversationId: number) {

    if (!this.searchTerm) {
      this.searchClicked = false;
    }

    this.blockUI.start();
    this.messagesService.getMessages(conversationId, 0, this.pageSize, this.searchTerm)
      .subscribe(
        data => {
          this.messages = data;
          //this.subscribeToData();
          this.blockUI.stop();
          this.subscribeToUpdates();
          this.isLoadMoreVisible = true;
        },
        error => {
          this.showResponseError(error);
        });
  }

  private subscribeToUpdates() {
    this.timeoutId = setTimeout(() => {
      console.log("Start check updates");
      this.messagesService.checkMessagesUpdates(this.conversationId, this.searchTerm).subscribe(
        data => {
          console.log("Updates result: " + data);
          if (data) {
            this.updateMessages();
          }
          else {
            this.subscribeToUpdates();
          }
        },
        error => {
          this.showError('Error in loading message updates from server. Please refresh the page or contact with Administrator');
          this.showResponseError(error);
        }
      );
    }, 2000);
  }

  private updateMessages() {
    this.messagesService.getMessagesUpdates(this.conversationId, this.searchTerm).subscribe(
      data => {
        // update messages
        console.log(data);

        data.forEach(obj => {
          var message = this.messages.filter(f => f.id == obj.id)[0];
          if (message) {
            message.date = obj.date;
            message.isRead = obj.isRead;
            message.isStatusChanged = obj.isStatusChanged;
            message.message = obj.message;
            message.smsSid = obj.smsSid;
            message.status = obj.status;
            message.contentType = obj.contentType;
            message.fileId = obj.fileId;
            message.fileName = obj.fileName;
            message.uniqueId = obj.uniqueId;
          }
          else {
            this.messages.push(obj);
          }
        });

        this.subscribeToUpdates();
      },
      error => {
        this.showError('Error in loading message updates from server. Please refresh the page or contact with Administrator');
        this.showResponseError(error);
      });
  }

  //private subscribeToData(): void {
  //  timer(1000).
  //  
  //  this.timerSubscription = Observable.timer(5000).first().subscribe(() => this.refreshData());
  //}

  public sendTextMessage() {

    if (!this.messageToSend) {
      this.showError('Message cannot be blank');
      return;
    }

    this.blockUI.start();
    console.log(this.messageToSend);
    var newMessageModel = new NewMessageModel();
    newMessageModel.conversationId = this.conversationId;
    newMessageModel.message = this.messageToSend;

    this.messagesService.sendTextMessage(newMessageModel).subscribe(
      data => {
        console.log(data);
        this.messages.push(<MessageModel>data);

        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
    this.messageToSend = '';
  }

  public sendPictureMessage(files) {
    if (files.length === 0) {
      this.showError('Please select picture');
      return;
    }

    const formData = new FormData();

    for (let file of files) {
      if (file.size > 600000) {
        this.showError('Allowed media less than 600 KB');
        return;
      }
      formData.append(file.name, file);
    }

    formData.append('ConversationId', this.conversationId.toString());

    this.blockUI.start();
    this.messagesService.sendPictureMessage(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response)
          this.messages.push(<MessageModel>event.body);

        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      });
  }

  public markCompleted() {
    this.confirmationDialogService.confirm('Confirmation', 'Do you really want to mark this conversation as Completed?')
      .then((confirmed) => {
        console.log(confirmed);
        this.conversationsService.markCompleted(this.conversationId).subscribe(
          data => {
            this.conversation = data;
          },
          error => {
            this.showResponseError(error);
          });
      })
      .catch(() => { });
  }

  public markOpen() {
    this.confirmationDialogService.confirm('Confirmation', 'Do you really want to open this conversation?')
      .then((confirmed) => {
        console.log(confirmed);
        this.conversationsService.markOpen(this.conversationId).subscribe(
          data => {
            this.conversation = data;
            this.subscribeToUpdates();
          },
          error => {
            this.showResponseError(error);
          });
      })
      .catch(() => { });
  }

  public assign() {
    this.assignAgentDialogService.getAgent('lg')
      .then((agent: AgentModel) => {

        this.blockUI.start();
        console.log(agent);

        this.agentsService.assignAgent(agent.id, this.conversation.id).subscribe(
          data => {
            this.blockUI.stop();
            this.router.navigate(['/conversations']);
          },
          error => {
            this.showResponseError(error);
          });
      })
      .catch(() => { });
  }

  public getMessageStatusString(status) {
    return MessageQueueStatusesEnum[status];
  }

  public getCountReceivedMessages() {
    if (this.messages)
      return this.messages.filter((obj) => obj.direction == DirectionsEnum.Inbound).length;
    return 0;
  }
  public getCountSentMessages() {
    if (this.messages)
      return this.messages.filter((obj) => obj.direction == DirectionsEnum.Outbound).length;
    return 0;
  }

  public loadMore() {
    this.isLoadMoreVisible = false;
    this.blockUI.start();
    this.messagesService.getMessages(this.conversationId, this.messages.length, this.pageSize, this.searchTerm)
      .subscribe(
        data => {
          for (var i = 0; i < data.length; i++) {
            this.messages.unshift(data[i]);
          }
          this.blockUI.stop();
          this.isLoadMoreVisible = data.length >= this.pageSize;
        },
        error => {
          this.showResponseError(error);
        });
  }

  public loadConversations(state: number) {
    this.sharedService.selectedButtonState = state;
    this.router.navigate(['/conversations'], { queryParams: {} });
  }

  public update() {
    var c = { ... this.conversation };

    this.updateConversationDialogService.getConversation(c, 'lg')
      .then((cc: ConversationModel) => {

        this.conversationsService.updateConversation(cc).subscribe(
          data => {
            this.conversation = data;
          },
          error => {
            this.showError('Error in updating conversation');
            this.showResponseError(error);
          });
      })
      .catch(() => { });

  }
}
