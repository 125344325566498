import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenModel } from '../../../models/tokenModel';
import { TokenDialogComponent } from './token-dialog.component';

@Injectable()
export class TokenDialogService {

  constructor(private modalService: NgbModal) { }

  public getDialog(
    /*btnOkText: string = 'Create',
    btnCancelText: string = 'Cancel',*/
    token: TokenModel,
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<TokenModel> {
    const modalRef = this.modalService.open(TokenDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.btnOkText = 'Save';
    modalRef.componentInstance.btnCancelText = 'Cancel';
    modalRef.componentInstance.token = { ...token };

    return modalRef.result;
  }
}
