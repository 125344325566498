import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';
import { TokenModel } from '../models/tokenModel';


@Injectable()
export class TokensService extends BaseService {

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  getTokens(): Observable<TokenModel[]> {
    return this
      .http
      .get<TokenModel[]>(this.ApiUrl + 'api/tokens');
  }

  postToken(token: TokenModel): Observable<TokenModel> {
    return this
      .http
      .post<TokenModel>(this.ApiUrl + 'api/tokens', token);
  }

  putToken(token: TokenModel): Observable<TokenModel> {
    return this
      .http
      .put<TokenModel>(this.ApiUrl + 'api/tokens/' + token.id, token);
  }

  deleteToken(id: number): Observable<any> {
    return this
      .http
      .delete(this.ApiUrl + 'api/tokens/' + id);
  }
}
