import { Component } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { SnotifyService } from 'ng-snotify';
import { PhoneNumberModel } from '../../../models/phoneNumberModel';
import { PhoneNumbersService } from '../../../services/phoneNumbersService';
import { ConfirmationDialogService } from '../../dialogs/confirmation-dialog/confirmation-dialog.service';
import { PhoneNumberDialogService } from '../../dialogs/phone-number-dialog/phone-number-dialog.service';
import { AuthService } from '../../../services/authService';
import { EnvService } from '../../../services/env.service';

@Component({
  selector: 'manage-twilio',
  templateUrl: './manage-twilio.component.html',
  styleUrls: ['./manage-twilio.component.css']
})
export class ManageTwilioComponent extends BaseComponent {

  public phoneNumbers: PhoneNumberModel[];

  constructor(
    private phoneNumbersService: PhoneNumbersService,
    private confirmationDialogService: ConfirmationDialogService,
    private phoneNumberDialog: PhoneNumberDialogService,
    snotifyService: SnotifyService,
    public authService: AuthService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.loadPhoneNumbers();
  }

  ngOnDestroy() {
  }


  loadPhoneNumbers() {
    this.blockUI.start();
    this.phoneNumbersService.getPhoneNumbers().subscribe(
      data => {
        this.phoneNumbers = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      });
  }

  editPhoneNumber(item: PhoneNumberModel) {
    if (item == null) {
      item = new PhoneNumberModel();
      item.id = 0;
    }

    this.phoneNumberDialog.getDialog(item, 'lg').then((data) => {
      if (data != null) {
        this.blockUI.start();

        if (data.id === 0) {
          this.phoneNumbersService.postPhoneNumber(data)
            .subscribe(
              data => {
                this.phoneNumbers.push(data);

                this.blockUI.stop();
              },
              error => {
                this.showResponseError(error);
              });
        }
        else {
          this.phoneNumbersService.putPhoneNumber(data)
            .subscribe(
              data => {
                var arrItem = this.phoneNumbers.filter((f) => f.id === data.id)[0];
                arrItem.accountSid = data.accountSid;
                arrItem.authToken = data.authToken;
                arrItem.phoneNumber = data.phoneNumber;

                this.blockUI.stop();
              },
              error => {
                this.showResponseError(error);
              }
            );
        }
      }
    }).catch(() => { });
  }

  deletePhoneNumber(item: PhoneNumberModel) {
    this.confirmationDialogService.confirm('Confirmation', 'Are you sure you want to delete phone ' + item.phoneNumber + '?')
      .then((confirmed: boolean) => {
        if (confirmed) {
          this.blockUI.start();
          this.phoneNumbersService.deletePhoneNumber(item.id)
            .subscribe(
              data => {
                console.log(data);
                this.loadPhoneNumbers();
              },
              error => {
                this.showResponseError(error);
              });
        }
      })
      .catch(() => { });
  }
}
