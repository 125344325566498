import { Component } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { BaseComponent } from '../../base/base.component';
import { BlockedViewModel } from '../../../models/blockedViewModel';
import { BlockedService } from '../../../services/blockedService';
import { EnvService } from '../../../services/env.service';

@Component({
  selector: 'manage-blocked',
  templateUrl: './manage-blocked.component.html',
  styleUrls: ['./manage-blocked.component.css']
})
export class ManageBlockedComponent extends BaseComponent {

  public blocked: BlockedViewModel[];

  constructor(
    private blockedService: BlockedService,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.loadBlocked();
  }

  ngOnDestroy() {
  }

  loadBlocked() {
    this.blockUI.start();

    this.blockedService.getBlocked().subscribe(
      data => {
        this.blocked = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      });
  }
}
