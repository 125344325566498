import { Component } from '@angular/core';
import { AuthService } from './services/authService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  

  constructor(public authService: AuthService, private router: Router) {

  }

  navigate(url) {
    this.router.navigate(['/' + url], {});
  }
}
