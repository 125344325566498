import { Input, Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AuthService } from "../../../services/authService";
import { HolidayModel } from "../../../models/businessHoursModel";

@Component({
  selector: 'holiday-dialog',
  templateUrl: './holiday-dialog.component.html',
  styleUrls: ['./holiday-dialog.component.css']
})
export class HolidayDialogComponent implements OnInit {

  today: string = new Date().toJSON().split('T')[0];

  @BlockUI() blockUI: NgBlockUI;

  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() holiday: HolidayModel;

  constructor(public activeModal: NgbActiveModal, public authService: AuthService) {
  }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.dismiss();
  }

  public accept() {
    this.activeModal.close(this.holiday);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
