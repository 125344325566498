import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './baseService';
import { Observable } from 'rxjs';
import { CommonResponseModel } from '../models/common';
import { BusinessHoursModel, HolidayModel } from '../models/businessHoursModel';
import { EnvService } from './env.service';


@Injectable()
export class AppService extends BaseService {

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  getBadWordsList(): Observable<CommonResponseModel> {
    return this
      .http
      .get<CommonResponseModel>(this.ApiUrl + 'api/app/badwordslist');
  }

  updateUpdateBadWordsList(value: string): Observable<any> {
    return this
      .http
      .post(this.ApiUrl + 'api/app/updatebadwordslist', {
        property: value
      });
  }

  getBusinessHours(): Observable<BusinessHoursModel> {
    return this
      .http
      .get<BusinessHoursModel>(this.ApiUrl + 'api/app/businesshours');
  }

  updateBusinessHours(bhModel: BusinessHoursModel): Observable<any> {
    return this
      .http
      .post(this.ApiUrl + 'api/app/updatebusinesshours', bhModel);
  }

  getHolidaysTotal(showPastHolidays: boolean): Observable<CommonResponseModel> {
    return this
      .http
      .get<CommonResponseModel>(this.ApiUrl + 'api/app/getholidaystotal?showPastHolidays=' + showPastHolidays);
  }

  getHolidays(showPastHolidays: boolean, skip: number, take: number): Observable<HolidayModel[]> {
    return this
      .http
      .get<HolidayModel[]>(this.ApiUrl + 'api/app/getholidays?showPastHolidays=' + showPastHolidays + '&skip=' + skip + '&take=' + take);
  }

  updateHoliday(holiday: HolidayModel): Observable<any> {
    return this
      .http
      .post(this.ApiUrl + 'api/app/updateholiday', holiday);
  }

  deleteHoliday(holiday: HolidayModel): Observable<any> {
    return this
      .http
      .post(this.ApiUrl + 'api/app/deleteholiday', holiday);
  }

  getTimeout(): Observable<CommonResponseModel> {
    return this
      .http
      .get<CommonResponseModel>(this.ApiUrl + 'api/app/timeout');
  }

  updateTimeout(value: number): Observable<any> {
    return this
      .http
      .post(this.ApiUrl + 'api/app/updatetimeout', {
        property: value
      });
  }

  getUploadTemplate(): Observable<CommonResponseModel> {
    return this
      .http
      .get<CommonResponseModel>(this.ApiUrl + 'api/app/uploadtemplate');
  }

  updateUploadTemplate(value: string): Observable<any> {
    return this
      .http
      .post(this.ApiUrl + 'api/app/updateuploadtemplate', {
        property: value
      });
  }

  generateUploadPreview(formData: FormData): Observable<CommonResponseModel> {
    return this
      .http
      .post<CommonResponseModel>(this.ApiUrl + 'api/app/generateUploadPreview', formData);
  }

  uploadExcelSend(formData: FormData): Observable<CommonResponseModel> {
    return this
      .http
      .post<CommonResponseModel>(this.ApiUrl + 'api/app/uploadExcelSend', formData);
  }
}
