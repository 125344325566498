import { Response } from '@angular/http';
import { Observable } from 'rxjs';

export class BaseService {
  public ApiUrl = '';
  
  // This method parses the data to JSON
  public parseDataToArray(res: Response) {
    return res.json() || [];
  }

  public parseDataToString(res: Response) {
    return res.json() || '';
  }

  // Displays the error message
  public handleError(error: Response | any) {
    let errorMessage: string;

    errorMessage = error.message ? error.message : error.toString();

    // In real world application, call to log error to remote server
    // logError(error);

    // This returns another Observable for the observer to subscribe to
    return Observable.throw(errorMessage);
  }
}
