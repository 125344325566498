import { Component } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { BaseComponent } from '../../base/base.component';
import { TemplatesService } from '../../../services/templatesService';
import { TemplateGroupModel, TemplateTypeModel, TemplateModel } from '../../../models/templateModels';
import { ConfirmationDialogService } from '../../dialogs/confirmation-dialog/confirmation-dialog.service';
import { TemplateDialogService } from '../../dialogs/template-dialog/template-dialog.service';
import { AuthService } from '../../../services/authService';
import { EnvService } from '../../../services/env.service';

@Component({
  selector: 'manage-templates',
  templateUrl: './manage-templates.component.html',
  styleUrls: ['./manage-templates.component.css']
})
export class ManageTemplatesComponent extends BaseComponent {

  templateGroups: TemplateGroupModel[];
  templateTypes: TemplateTypeModel[];
  templates: TemplateModel[];

  templateGroup: TemplateGroupModel;
  templateType: TemplateTypeModel;

  constructor(
    private templatesService: TemplatesService,
    private confirmationDialogService: ConfirmationDialogService,
    private templateDialogService: TemplateDialogService,
    public authService: AuthService,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.loadTemplateGroups();
  }

  ngOnDestroy() {
  }

  loadTemplateGroups() {
    this.blockUI.start();
    this.templatesService.getTemplateGroups().subscribe(
      data => {
        this.templateGroups = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      });
  }

  loadTemplateTypes() {
    this.blockUI.start();
    this.templatesService.getTemplateTypes(this.templateGroup.id).subscribe(
      data => {
        this.templateTypes = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
  }

  loadTemplates() {
    this.blockUI.start();
    this.templatesService.getTemplates(this.templateType.id).subscribe(
      data => {
        this.templates = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
  }

  templateGroupChanged() {
    this.templateType = null;
    this.loadTemplateTypes();
  }

  templateTypeChanged() {
    this.loadTemplates();
  }

  editTemplate(item: TemplateModel) {
    if (item == null) {
      item = new TemplateModel();
      item.id = 0;
    }

    this.templateDialogService.getDialog(item, this.templateType, 'lg').then((data) => {
      if (data != null) {
        this.blockUI.start();

        if (data.id === 0) {
          this.templatesService.postTemplate(data)
            .subscribe(
              data => {
                this.templates.push(data);

                this.blockUI.stop();
              },
              error => {
                this.showResponseError(error);
              });
        }
        else {
          this.templatesService.putTemplate(data)
            .subscribe(
              data => {
                var arrItem = this.templates.filter((f) => f.id === data.id)[0];
                arrItem.templateBody = data.templateBody;
                arrItem.templateName = data.templateName;

                this.blockUI.stop();
              },
              error => {
                this.showResponseError(error);
              }
            );
        }
      }
    }).catch(() => { });
  }

  deleteTemplate(item: TemplateModel) {
    this.confirmationDialogService.confirm('Confirmation', 'Are you sure you want to delete template "' + item.templateName + '"?')
      .then((confirmed: boolean) => {
        if (confirmed) {
          this.blockUI.start();
          this.templatesService.deleteTemplate(item.id)
            .subscribe(
              data => {
                this.templates = this.templates.filter((f) => f.id != item.id);
                this.blockUI.stop();
              },
              error => {
                this.showResponseError(error);
              });
        }
      })
      .catch(() => { });
  }
}
