import { HttpInterceptor, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpHandler } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, finalize } from 'rxjs/operators';
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  @BlockUI() blockUI: NgBlockUI;

  constructor(private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
      tap(
        (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }}, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // redirect to the login route
            // or show a modal

            this.blockUI.stop();
            this.router.navigate(['/login']);
          }
        }
        }),
      finalize(() => {
      }));
  }
}
