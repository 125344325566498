import { Component } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { BaseComponent } from '../../base/base.component';
import { AppService } from '../../../services/appService';
import { AuthService } from '../../../services/authService';
import { EnvService } from '../../../services/env.service';

@Component({
  selector: 'manage-bad-word-list',
  templateUrl: './manage-bad-word-list.component.html',
  styleUrls: ['./manage-bad-word-list.component.css']
})
export class ManageBadWordsListComponent extends BaseComponent {

  public badWordsList: string;

  constructor(
    private appService: AppService,
    snotifyService: SnotifyService,
    public authService: AuthService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.loadList();
  }

  loadList() {
    this.blockUI.start();
    this.appService.getBadWordsList().subscribe(
      data => {
        this.badWordsList = data.property;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
  }

  ngOnDestroy() {
  }

  save() {
    this.blockUI.start();
    this.appService.updateUpdateBadWordsList(this.badWordsList).subscribe(
      data => {
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
  }
}
