import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';
import { HttpClient } from '@angular/common/http';
import { AgentModel } from '../models/agentModel';
import { EnvService } from './env.service';


@Injectable()
export class AgentsService extends BaseService {

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  getAgents(inactive: boolean): Observable<AgentModel[]> {
    return this
      .http
      .get<AgentModel[]>(this.ApiUrl + 'api/agents/getagents?inactive=' + inactive);
  }

  assignAgent(agentId: number, conversationId: number): Observable<boolean> {
    return this
      .http
      .get<boolean>(this.ApiUrl + 'api/agents/assignagent?agentId=' + agentId + '&conversationId=' + conversationId);
  }

  assignAgentList(agentId: number, conversationIds: number[]): Observable<boolean> {
    return this
      .http
      .post<boolean>(this.ApiUrl + 'api/agents/assignagentlist', {
        'agentId': agentId,
        'conversationIds': conversationIds
      });
  }

  changeAgentStatus(id: number, value: boolean): Observable<any> {
    return this
      .http
      .get(this.ApiUrl + 'api/agents/changeAgentStatus?id=' + id + '&value=' + value);
  }

  updateAgentPhone(agent: AgentModel): Observable<any> {
    return this
      .http
      .post(this.ApiUrl + 'api/agents/UpdateAgentPhone', agent);
  }

  unAssignPhone(agent: AgentModel): Observable<any> {
    return this
      .http
      .post(this.ApiUrl + 'api/agents/UnAssignPhone', agent);
  }
}
