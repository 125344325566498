import { Component, Inject } from '@angular/core';
import { ConversationStatusesEnum } from '../../models/enums';
import { Router } from '@angular/router';
import { ConversationModel } from '../../models/conversationModel';
import { ConversationsService } from '../../services/conversationsService';
import { BaseComponent } from '../base/base.component';
import { SnotifyService } from 'ng-snotify';
import { ConversationUpdateModel } from '../../models/conversationUpdateModel';
import { ConfirmationDialogService } from '../dialogs/confirmation-dialog/confirmation-dialog.service';
import { NewConversationDialogService } from '../dialogs/new-conversation-dialog/new-conversation-dialog.service';
import { AssignAgentDialogService } from '../dialogs/assign-agent-dialog/assign-agent-dialog.service';
import { AgentModel } from '../../models/agentModel';
import { AgentsService } from '../../services/agentsService';
import { AuthService } from '../../services/authService';
import { SharedService } from '../../services/sharedService';
import { EnvService } from '../../services/env.service';

@Component({
  selector: 'conversations-component',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.css']
})
export class ConversationsComponent extends BaseComponent {
  public selectedButtonState: number;
  public conversations: ConversationModel[];
  public agents: AgentModel[];
  public events: string[] = [];
  private timeoutId: any;
  private secondsToNextUpdate: number = 10;
  public secondsLeft: number;
  public secondsLeftString: string = '-';
  public searchTerm: string = '';
  public searchClicked: boolean = false;
  public readUnreadFilter: string = '';
  public selectedAgent: string = '';
  public selectedMessageDate: string = '';
  public selectedEventName: string = '';

  public orderByField: string = 'CreatedDate';
  public orderDir: string = 'DESC';

  public selectAll: boolean;

  // pagination properties
  public page: number = 1;
  public pageSize: number = 100;
  public collectionSize: number = 0;
  private latestConversationId: number = 0;

  constructor(
    private conversationsService: ConversationsService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService,
    private newConversationDialogService: NewConversationDialogService,
    private assignAgentDialogService: AssignAgentDialogService,
    private sharedService: SharedService,
    private agentsService: AgentsService,
    public authService: AuthService,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.loadAgents();
    this.loadEvents();

    this.secondsLeft = this.secondsToNextUpdate;
    this.selectedButtonState = sharedService.selectedButtonState;

    console.log('conv init');
    this.searchTerm = this.sharedService.convFilterTxt;
    if (this.searchTerm && this.searchTerm.length > 0) {
      this.searchClicked = true;
    }
    this.readUnreadFilter = this.sharedService.convFilterReadUnread;
    this.selectedMessageDate = this.sharedService.convFilterMessageDate;
    this.selectedAgent = this.sharedService.convFilterAgent;
    this.selectedEventName = this.sharedService.convFilterEventName;

    this.loadConversationsCount(this.selectedButtonState);
    //this.loadConversations(this.selectedButtonState);
  }

  loadAgents() {
    this.selectedAgent = '';
    this.agentsService.getAgents(false).subscribe(
      data => {
        this.agents = data;
      }
    )
  }
  loadEvents() {
    this.conversationsService.getEvents().subscribe(
      data => {
        this.events = data;

        console.log(this.events);
      }
    )
  }

  private destroyTimer() {
    this.secondsLeft = this.secondsToNextUpdate;
    if (this.timeoutId) {
      console.log('Destroy timeout for conversations');
      clearTimeout(this.timeoutId);
      this.secondsLeftString = '-';
    }
  }

  ngOnDestroy() {
    this.destroyTimer();
  }

  public onChangeObj(newReadUnreadFilter: string) {
    this.readUnreadFilter = newReadUnreadFilter;
    //console.log(this.readUnreadFilter);

    this.loadConversationsCount(this.selectedButtonState);
  }

  public onMessageDateChange(date: any) {
    this.selectedMessageDate = date;
    //console.log(this.selectedMessageDate);

    this.loadConversationsCount(this.selectedButtonState);
  }

  public changeView(state) {
    this.selectedAgent = '';
    this.selectedMessageDate = '';

    this.loadConversationsCount(state);
  }

  public loadConversationsCount(state) {
    this.selectedButtonState = state;

    this.destroyTimer();

    this.blockUI.start();
    this.conversationsService.getConversationsCount(state, this.searchTerm, this.readUnreadFilter, this.selectedAgent, this.selectedMessageDate, this.selectedEventName)
      .subscribe(
      data => {
          this.collectionSize = data.total;
          this.latestConversationId = data.latestConversationId;

          this.blockUI.stop();
          this.loadConversations(state);
        },
        error => {
          this.showResponseError(error);
        }
      );
  }

  public onPageChange() {
    this.loadConversations(this.selectedButtonState);
  }

  public loadConversations(state) {
    this.selectedButtonState = state;

    this.destroyTimer();

    if (!this.searchTerm) {
      this.searchClicked = false;
    }

    var take = this.pageSize;
    var skip = (this.page - 1) * this.pageSize;

    //dfs
    this.blockUI.start();
    // where state: 0 - my open, 1 - my completed, 2 - all open, 3 - all completed, 4 - unassigned, 5 - disconnected
    if (state < 2) {
      this.conversationsService.getConversations(state == 0 ? ConversationStatusesEnum.Open : ConversationStatusesEnum.Completed, skip, take, this.searchTerm, this.orderByField, this.orderDir, this.readUnreadFilter, this.selectedMessageDate, this.selectedEventName)
        .subscribe(
          data => {
            this.conversationsLoaded(data);
          },
          error => {
            this.showResponseError(error);
          });
    }
    else if (state < 4) {
      this.conversationsService.getAllConversations(state == 2 ? ConversationStatusesEnum.Open : ConversationStatusesEnum.Completed, skip, take, this.searchTerm, this.orderByField, this.orderDir, this.readUnreadFilter, this.selectedAgent, this.selectedMessageDate, this.selectedEventName)
        .subscribe(
          data => {
            this.conversationsLoaded(data);
          },
          error => {
            this.showResponseError(error);
          });
    }
    else if (state === 4) {
      this.conversationsService.getUnassignedConversations(skip, take, this.searchTerm, this.orderByField, this.orderDir, this.readUnreadFilter, this.selectedMessageDate, this.selectedEventName)
        .subscribe(
          data => {
            this.conversationsLoaded(data);
          },
          error => {
            this.showResponseError(error);
          });
    }
    else if (state === 5) {
      this.conversationsService.getDisconnectedConversations(skip, take, this.searchTerm, this.orderByField, this.orderDir, this.readUnreadFilter, this.selectedMessageDate, this.selectedEventName)
        .subscribe(
          data => {
            this.conversationsLoaded(data);
          },
          error => {
            this.showResponseError(error);
          });
    }
  }

  private conversationsLoaded(data: ConversationModel[]) {
    this.conversations = data;
    this.blockUI.stop();
    this.secondsLeft = this.secondsToNextUpdate;
    if (this.page == 1 && !this.searchClicked) {
      this.subscribeToUpdates();
    }
  }

  private loadUpdatesFromServer() {
    //console.log("Start check conversation updates");
    var convIds = this.conversations.map(({ id }) => id);
    //console.log(convIds);

    var take = this.pageSize;
    var skip = (this.page - 1) * this.pageSize;

    this.conversationsService.checkConversationUpdates(skip, take, convIds, this.selectedButtonState, this.searchTerm, this.orderByField, this.orderDir, this.latestConversationId).subscribe(
      data => {
        //console.log("Updates result: ");
        //console.log(data);

        this.secondsLeft = this.secondsToNextUpdate;

        this.updateConversations(data);
        this.subscribeToUpdates();
      },
      error => {
        this.showError('Error in loading updates from server. Please refresh the page or contact with Administrator.');
        this.showResponseError(error);
      });
  }

  private subscribeToUpdates() {
    this.timeoutId = setTimeout(() => {
      this.secondsLeft--;
      this.secondsLeftString = this.secondsLeft.toString();

      if (this.secondsLeft === 0) {

        this.destroyTimer();

        this.secondsLeftString = '-';
        this.loadUpdatesFromServer();
      }
      else {
        this.subscribeToUpdates();
      }

    }, 1000);
  }

  private updateConversations(conversationUpdModel: ConversationUpdateModel) {

    this.latestConversationId = conversationUpdModel.latestConversationId;

    if (conversationUpdModel && conversationUpdModel.removedConversations) {
      for (var i = 0; i < conversationUpdModel.removedConversations.length; i++) {
        var ind = this.conversations.findIndex(c => c.id === conversationUpdModel.removedConversations[i]);
        this.conversations.splice(ind, 1);
      }
    }

    if (conversationUpdModel && conversationUpdModel.updatedConversation) {
      for (var i = 0; i < conversationUpdModel.updatedConversation.length; i++) {
        var conv = this.conversations.filter((c) => c.id == conversationUpdModel.updatedConversation[i].id)[0];
        conv.haveUnread = conversationUpdModel.updatedConversation[i].haveUnread;

        conv.body = conversationUpdModel.updatedConversation[i].body;
        conv.contactName = conversationUpdModel.updatedConversation[i].contactName;
        conv.date = conversationUpdModel.updatedConversation[i].date;
        conv.fileId = conversationUpdModel.updatedConversation[i].fileId;
        conv.contentType = conversationUpdModel.updatedConversation[i].contentType;
        conv.fileName = conversationUpdModel.updatedConversation[i].fileName;
        conv.uniqueId = conversationUpdModel.updatedConversation[i].uniqueId;
        conv.phone = conversationUpdModel.updatedConversation[i].phone;
        conv.previousAgent = conversationUpdModel.updatedConversation[i].previousAgent;
        conv.status = conversationUpdModel.updatedConversation[i].status;
      }
    }

    if (conversationUpdModel && conversationUpdModel.addedConversations) {
      for (var i = 0; i < conversationUpdModel.addedConversations.length; i++) {
        this.conversations.push(conversationUpdModel.addedConversations[i]);
      }
      //this.conversations = this.conversations.sort(function (a, b) {
      //  if (a.date && b.date)
      //    return (new Date(b.date)).getTime() - (new Date(a.date)).getTime();

      //  return 0;
      //});
    }
  }

  public getMinutesWaiting(conversation: ConversationModel) {
    return 0;
  }

  public openConversation(conversation: ConversationModel) {
    this.sharedService.selectedButtonState = this.selectedButtonState;
    this.sharedService.cnt = this.conversations.length;

    this.sharedService.convFilterAgent = this.selectedAgent;
    this.sharedService.convFilterMessageDate = this.selectedMessageDate;
    this.sharedService.convFilterReadUnread = this.readUnreadFilter;
    this.sharedService.convFilterTxt = this.searchTerm;
    this.sharedService.convFilterEventName = this.selectedEventName;

    this.router.navigate(['/conversation-thread'], { queryParams: { conversationId: conversation.id } });
  }

  public newConversation() {

    var c = new ConversationModel();
    c.body = '';
    c.contactName = '';
    c.date = new Date();
    c.id = 0;
    c.phone = '';
    c.previousAgent = '';
    c.status = ConversationStatusesEnum.Open;
    var selectedConversations = this.conversations.filter(c => c.selected);
    if (!selectedConversations || selectedConversations.length != 1) {
      this.newConversationDialogService.getPhoneNumber(c, 'lg')
        .then((cc: ConversationModel) => {
          console.log(cc);

          if (cc) {
            c.contactPhone = cc.contactPhone;
            c.eventName = cc.eventName;
            c.teamName = cc.teamName;
            c.contactName = cc.contactName;
            this.conversationsService.newConversation(c).subscribe(
              data => {
                this.openConversation(data);
              },
              error => {
                this.showResponseError(error);
              });
          }
        })
        .catch(() => { });
    }
    else {
      c.id = selectedConversations[0].id;
      this.conversationsService.newConversation(c).subscribe(
        data => {
          this.openConversation(data);
        },
        error => {
          this.showResponseError(error);
        });
    }
  }

  public assign() {

    var selectedConversationIds = this.conversations.filter(c => c.selected).map(c => c.id);

    if (selectedConversationIds == null || selectedConversationIds.length === 0) {
      this.showError('Please select some conversation(s) to assign');
      return;
    }

    this.assignAgentDialogService.getAgent('lg')
      .then((agent: AgentModel) => {

        this.destroyTimer();
        this.blockUI.start();
        console.log(agent);

        this.agentsService.assignAgentList(agent.id, selectedConversationIds).subscribe(
          data => {
            this.blockUI.stop();
            this.loadConversations(this.selectedButtonState);
          },
          error => {
            this.showResponseError(error);
          });
      })
      .catch(() => { });
  }

  public markCompleted() {

    var selectedConversationIds = this.conversations.filter(c => c.selected).map(c => c.id);
    if (selectedConversationIds == null || selectedConversationIds.length == 0) {
      this.showError('Please select some conversation(s) to complete');
      return;
    }

    this.confirmationDialogService.confirm('Confirmation', 'Do you really want to mark this conversation as Completed ?')
      .then((confirmed: boolean) => {
        if (confirmed) {
          console.log(confirmed);
          this.conversationsService.markCompletedList(selectedConversationIds).subscribe(
            data => {
              this.loadConversations(this.selectedButtonState);
            },
            error => {
              this.showResponseError(error);
            }
          );
        }
      })
      .catch(() => { });
  }

  public markReadUnread(read: boolean) {

    var selectedConversationIds = this.conversations.filter(c => c.selected).map(c => c.id);
    if (selectedConversationIds == null || selectedConversationIds.length == 0) {
      this.showError('Please select some conversation(s)');
      return;
    }

    var txt = "Read?";
    if (!read) txt = "Unread?";
    this.confirmationDialogService.confirm('Confirmation', 'Do you really want to mark this conversation as ' + txt)
      .then((confirmed: boolean) => {
        if (confirmed) {
          //console.log(confirmed);
          this.conversationsService.markReadUnreadList(selectedConversationIds, read).subscribe(
            data => {
              this.loadConversations(this.selectedButtonState);
            },
            error => {
              this.showResponseError(error);
            }
          );
        }
      })
      .catch(() => { });
  }

  public selectConversation(conversation: ConversationModel) {
    conversation.selected = !conversation.selected;
  }

  public toggleSort(field) {
    if (this.orderByField == field) {
      if (this.orderDir == 'ASC') this.orderDir = 'DESC';
      else this.orderDir = 'ASC';
    }
    else {
      this.orderByField = field;
      this.orderDir = 'DESC';
    }

    this.destroyTimer();
    this.conversations = [];
    this.loadConversations(this.selectedButtonState);
  }

  public selectionChange(item: ConversationModel) {
    //console.log(item.id + ' ' + item.selected);

    var cnt = this.conversations.filter((c) => c.selected == item.selected).length;
    if (item.selected === false && cnt !== this.conversations.length) {
      this.selectAll = false;
    }
    else if (item.selected === true && cnt === this.conversations.length) {
      this.selectAll = true;
    }
  }

  public selectAllChanged() {
    //console.log(this.selectAll);

    for (var i = 0; i < this.conversations.length; i++) {
      this.conversations[i].selected = this.selectAll;
    }
  }

  public isNewButtonDisabled() {
    if (this.conversations) {
      var cnt = this.conversations.filter((c) => c.selected).length;
      return cnt > 1;
    }
    return true;
  }

  public onAgentChange(newAgent: any) {
    this.loadConversationsCount(this.selectedButtonState);
  }

  public onEventChange(newEvent: any) {
    console.log(this.selectedEventName);
    this.loadConversationsCount(this.selectedButtonState);
  }
}
