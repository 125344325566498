import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateModel, TemplateTypeModel } from '../../../models/templateModels';
import { TemplateDialogComponent } from './template-dialog.component';

@Injectable()
export class TemplateDialogService {

  constructor(private modalService: NgbModal) { }

  public getDialog(
    /*btnOkText: string = 'Create',
    btnCancelText: string = 'Cancel',*/
    template: TemplateModel,
    templateType: TemplateTypeModel,
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<TemplateModel> {
    const modalRef = this.modalService.open(TemplateDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.btnOkText = 'Save';
    modalRef.componentInstance.btnCancelText = 'Cancel';
    modalRef.componentInstance.template = { ...template };
    modalRef.componentInstance.template.templateTypeId = templateType.id;
    modalRef.componentInstance.templateType = { ...templateType };

    return modalRef.result;
  }
}
