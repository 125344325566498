import { Component } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { BaseComponent } from '../../base/base.component';
import { AppService } from '../../../services/appService';
import { AuthService } from '../../../services/authService';
import { EnvService } from '../../../services/env.service';

@Component({
  selector: 'manage-timeout',
  templateUrl: './manage-timeout.component.html',
  styleUrls: ['./manage-timeout.component.css']
})
export class ManageTimeoutComponent extends BaseComponent {

  public timeout: number;

  constructor(
    private appService: AppService,
    public authService: AuthService,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.loadTimeout();
  }

  loadTimeout() {
    this.blockUI.start();
    this.appService.getTimeout().subscribe(
      data => {
        this.timeout = data.property;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
  }

  ngOnDestroy() {
  }

  save() {
    this.blockUI.start();
    this.appService.updateTimeout(this.timeout).subscribe(
      data => {
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
  }
}
