import { Injectable } from '@angular/core';
import { BaseService } from './baseService';
import { HttpClient } from '@angular/common/http';
import { AuthModel } from '../models/authModel';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { EnvService } from './env.service';

@Injectable()
export class AuthService extends BaseService {

  auth: AuthModel;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private http: HttpClient,
    private router: Router,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  public isAuthenticated(fallback): Observable<boolean> {

    console.log('Check is authenticated');

    if (this.auth && this.auth.expiresAt > new Date()) {
      return of(this.auth.isAuthenticated);
    }

    return this
      .http
      .get(this.ApiUrl + 'api/app/IsAuthenticated')
      .pipe(
        map((d: AuthModel) => {
          console.log(d);
          this.auth = d;
          if (!this.auth.isAuthenticated) {
            console.log('not authenticated - call fallbak');
            fallback();
          }
          return d.isAuthenticated;
        }));
  }

  public getUserName() {
    if (this.auth && this.auth.user) {
      return this.auth.user.name;
    }

    return '';
  }

  public isUsageView() {
    if (this.auth && this.auth.user) {
      return this.auth.usageAccessRightLevel >= 2; 
    }
    return false;
  }
  public isUsageEdit() {
    if (this.auth && this.auth.user) {
      return this.auth.usageAccessRightLevel === 3;
    }
    return false;
  }
  public isManageView() {
    if (this.auth && this.auth.user) {
      return this.auth.manageAccessRightLevel >= 2;
    }
    return false;
  }
  public isManageEdit() {
    if (this.auth && this.auth.user) {
      return this.auth.manageAccessRightLevel === 3;
    }
    return false;
  }

  public logout() {
    this.blockUI.start();

    this
      .http
      .get(this.ApiUrl + 'api/app/logout')
      .subscribe((o) => {
        this.auth = null;
        this.blockUI.stop();
        this.router.navigate(['/login']);
      });
  }
}
