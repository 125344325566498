import { Input, Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AgentsService } from "../../../services/agentsService";
import { AgentModel } from "../../../models/agentModel";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BaseComponent } from "../../base/base.component";
import { SnotifyService } from "ng-snotify";
import { EnvService } from "../../../services/env.service";

@Component({
  selector: 'assign-agent-dialog-component',
  templateUrl: './assign-agent-dialog.component.html',
  styleUrls: ['./assign-agent-dialog.component.css']
})
export class AssignAgentDialogComponent extends BaseComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  public agent: AgentModel;
  public agents: AgentModel[];

  constructor(
    public activeModal: NgbActiveModal,
    private agentsService: AgentsService,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);
  }

  ngOnInit() {
    this.blockUI.start();
    this.agentsService.getAgents(false).subscribe(
      data => {
        this.agents = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      });
  }

  public decline() {
    this.activeModal.dismiss();
  }

  public accept() {
    this.activeModal.close(this.agent);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
