import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { HttpModule } from '@angular/http';
import { AppService } from './services/appService';
import { ConversationsComponent } from './components/conversations/conversations.component';
import { ConversationThreadComponent } from './components/conversation-thread/conversation-thread.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ConversationsService } from './services/conversationsService';
import { MessagesService } from './services/MessagesService';

import { BlockUIModule } from 'ng-block-ui';
import { AuthGuardService } from './services/authGuardService';
import { AuthService } from './services/authService';
import { LoginComponent } from './components/login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './components/dialogs/confirmation-dialog/confirmation-dialog.service';
import { NewConversationDialogComponent } from './components/dialogs/new-conversation-dialog/new-conversation-dialog.component';
import { NewConversationDialogService } from './components/dialogs/new-conversation-dialog/new-conversation-dialog.service';
import { AuthInterceptor } from './auth/authInterceptor';
import { AssignAgentDialogComponent } from './components/dialogs/assign-agent-dialog/assign-agent-dialog.component';
import { AssignAgentDialogService } from './components/dialogs/assign-agent-dialog/assign-agent-dialog.service';
import { AgentsService } from './services/agentsService';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab.component';
import { ManageComponent } from './components/manage/manage.component';
import { PhoneNumbersService } from './services/phoneNumbersService';
import { PhoneNumberDialogService } from './components/dialogs/phone-number-dialog/phone-number-dialog.service';
import { PhoneNumberDialogComponent } from './components/dialogs/phone-number-dialog/phone-number-dialog.component';
import { EditAgentPhoneDialogComponent } from './components/dialogs/edit-agent-phone-dialog/edit-agent-phone-dialog.component';
import { EditAgentPhoneDialogService } from './components/dialogs/edit-agent-phone-dialog/edit-agent-phone-dialog.service';
import { HighlightSearch } from './components/base/highlight-search';
import { ManageAgentsComponent } from './components/manage/manage-agents/manage-agents.component';
import { ManageTwilioComponent } from './components/manage/manage-twilio/manage-twilio.component';
import { ManageBadWordsListComponent } from './components/manage/manage-bad-word-list/manage-bad-word-list.component';
import { ManageTemplatesComponent } from './components/manage/manage-templates/manage-templates.component';
import { TemplatesService } from './services/templatesService';
import { TemplateDialogComponent } from './components/dialogs/template-dialog/template-dialog.component';
import { TemplateDialogService } from './components/dialogs/template-dialog/template-dialog.service';
import { ManageBusinessHoursComponent } from './components/manage/manage-business-hours/manage-business-hours.component';
import { HolidayDialogComponent } from './components/dialogs/holiday-dialog/holiday-dialog.component';
import { HolidayDialogService } from './components/dialogs/holiday-dialog/holiday-dialog.service';
import { InfiniteScrollDirective } from './components/base/infinite-scroll-directive';
import { ManageTimeoutComponent } from './components/manage/manage-timeout/manage-timeout.component';
import { BlockedService } from './services/blockedService';
import { ManageBlockedComponent } from './components/manage/manage-blocked/manage-blocked.component';
import { SharedService } from './services/sharedService';
import { ManageUploadsComponent } from './components/manage/manage-uploads/manage-uploads.component';
import { PhonePipe } from './components/base/phone-pipe';
import { UploadExcelService } from './services/uploadExcelService';
import { UploadExcelTemplateDialogService } from './components/dialogs/upload-excel-template-dialog/upload-excel-template-dialog.service';
import { UploadExcelTemplateDialogComponent } from './components/dialogs/upload-excel-template-dialog/upload-excel-template-dialog.component';
import { UpdateConversationDialogComponent } from './components/dialogs/update-conversation-dialog/update-conversation-dialog.component';
import { UpdateConversationDialogService } from './components/dialogs/update-conversation-dialog/update-conversation-dialog.service';
import { EnvServiceProvider } from './services/env.service.provider';
import { ManageTokensComponent } from './components/manage/manage-tokens/manage-tokens.component';
import { TokenDialogComponent } from './components/dialogs/token-dialog/token-dialog.component';
import { TokensService } from './services/tokensService';
import { TokenDialogService } from './components/dialogs/token-dialog/token-dialog.service';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    ConversationsComponent,
    ConversationThreadComponent,
    LoginComponent,
    ManageComponent,
    ManageAgentsComponent,
    ManageTwilioComponent,
    ManageBadWordsListComponent,
    ManageTemplatesComponent,
    ManageBusinessHoursComponent,
    ManageTimeoutComponent,
    ManageBlockedComponent,
    ManageUploadsComponent,

    ConfirmationDialogComponent,
    NewConversationDialogComponent,
    AssignAgentDialogComponent,
    PhoneNumberDialogComponent,
    EditAgentPhoneDialogComponent,
    TemplateDialogComponent,
    HolidayDialogComponent,
    UploadExcelTemplateDialogComponent,
    UpdateConversationDialogComponent,

    TabsComponent,
    TabComponent,

    HighlightSearch,
    InfiniteScrollDirective,
    PhonePipe,
    ManageTokensComponent,
    TokenDialogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: ConversationsComponent, pathMatch: 'full', canActivate: [AuthGuardService] },
      { path: 'login', component: LoginComponent },
      { path: 'conversations', component: ConversationsComponent, canActivate: [AuthGuardService] },
      { path: 'conversation-thread', component: ConversationThreadComponent, canActivate: [AuthGuardService] },
      { path: 'manage', component: ManageComponent, canActivate: [AuthGuardService] },
      { path: 'manage-agents', component: ManageAgentsComponent, canActivate: [AuthGuardService] },
      { path: 'manage-twilio', component: ManageTwilioComponent, canActivate: [AuthGuardService] },
      { path: 'manage-bad-word-list', component: ManageBadWordsListComponent, canActivate: [AuthGuardService] },
      { path: 'manage-templates', component: ManageTemplatesComponent, canActivate: [AuthGuardService] },
      { path: 'manage-business-hours', component: ManageBusinessHoursComponent, canActivate: [AuthGuardService] },
      { path: 'manage-timeout', component: ManageTimeoutComponent, canActivate: [AuthGuardService] },
      { path: 'manage-blocked', component: ManageBlockedComponent, canActivate: [AuthGuardService] },
      { path: 'manage-uploads', component: ManageUploadsComponent, canActivate: [AuthGuardService] },
      { path: 'manage-tokens', component: ManageTokensComponent, canActivate: [AuthGuardService] }
    ]),
    HttpModule,
    SnotifyModule,
    BlockUIModule.forRoot(),
    NgbModule.forRoot()
  ],
  entryComponents: [ConfirmationDialogComponent, NewConversationDialogComponent, AssignAgentDialogComponent, PhoneNumberDialogComponent, EditAgentPhoneDialogComponent, TemplateDialogComponent, HolidayDialogComponent, UploadExcelTemplateDialogComponent, UpdateConversationDialogComponent, TokenDialogComponent],
  exports: [
    RouterModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    AppService,
    ConversationsService,
    MessagesService,
    AgentsService,
    PhoneNumbersService,
    TemplatesService,
    BlockedService,
    SharedService,

    ConfirmationDialogService,
    NewConversationDialogService,
    AssignAgentDialogService,
    PhoneNumberDialogService,
    EditAgentPhoneDialogService,
    TemplateDialogService,
    HolidayDialogService,
    UploadExcelService,
    UploadExcelTemplateDialogService,
    UpdateConversationDialogService,
    TokensService,
    TokenDialogService,

    EnvServiceProvider,

    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
