import { Component } from "@angular/core";
import { BaseComponent } from "../base/base.component";
import { SnotifyService } from "ng-snotify";
import { EnvService } from "../../services/env.service";

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent {

  constructor(
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    })
  }

  navigateEventGenieApp() {
    window.location.href = this.env.eventGenieAppURL + 'Authentication/Login?ag=' + this.newGuid();
  }
}
