import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';
import { HttpClient } from '@angular/common/http';
import { BlockedViewModel } from '../models/blockedViewModel';
import { EnvService } from './env.service';


@Injectable()
export class BlockedService extends BaseService {

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  getBlocked(): Observable<BlockedViewModel[]> {
    return this
      .http
      .get<BlockedViewModel[]>(this.ApiUrl + 'api/blocked/getblocked');
  }
}
