import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditAgentPhoneDialogComponent } from './edit-agent-phone-dialog.component';
import { PhoneNumberModel } from '../../../models/phoneNumberModel';

@Injectable()
export class EditAgentPhoneDialogService {

  constructor(private modalService: NgbModal) { }

  public getDialog(
    /*btnOkText: string = 'Create',
    btnCancelText: string = 'Cancel',*/
    phoneNumber: PhoneNumberModel,
    icmUsername: string,
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<any> {
    const modalRef = this.modalService.open(EditAgentPhoneDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.btnOkText = 'Save';
    modalRef.componentInstance.btnCancelText = 'Cancel';
    modalRef.componentInstance.phoneNumber = { ...phoneNumber };
    modalRef.componentInstance.icmUsername = icmUsername;

    return modalRef.result;
  }
}
