import { ConversationStatusesEnum } from "./enums";

export class ConversationModel {
  id: number;
  date: Date;
  contactName: string;
  contactPhone: string;
  phone: string;
  previousAgent: string;
  body: string;
  fileId: number;
  contentType: string;
  fileName: string;
  uniqueId: string;
  status: ConversationStatusesEnum;
  teamId: number;
  teamName: string;
  eventId: number;
  eventName: string;
  haveUnread: boolean;
  agentName: string;
  createdDate: Date;
  selected: boolean;
}
