import { Component } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { BaseComponent } from '../../base/base.component';
import { ConfirmationDialogService } from '../../dialogs/confirmation-dialog/confirmation-dialog.service';
import { BusinessHoursModel, HolidayModel } from '../../../models/businessHoursModel';
import { AppService } from '../../../services/appService';
import { HolidayDialogService } from '../../dialogs/holiday-dialog/holiday-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/authService';
import { EnvService } from '../../../services/env.service';

@Component({
  selector: 'manage-business-hours',
  templateUrl: './manage-business-hours.component.html',
  styleUrls: ['./manage-business-hours.component.css']
})
export class ManageBusinessHoursComponent extends BaseComponent {

  times: any[] = [
    { 'key': '0000', 'value': '12:00 AM' },
    { 'key': '0030', 'value': '12:30 AM' },
    { 'key': '0100', 'value': '01:00 AM' },
    { 'key': '0130', 'value': '01:30 AM' },
    { 'key': '0200', 'value': '02:00 AM' },
    { 'key': '0230', 'value': '02:30 AM' },
    { 'key': '0300', 'value': '03:00 AM' },
    { 'key': '0330', 'value': '03:30 AM' },
    { 'key': '0400', 'value': '04:00 AM' },
    { 'key': '0430', 'value': '04:30 AM' },
    { 'key': '0500', 'value': '05:00 AM' },
    { 'key': '0530', 'value': '05:30 AM' },
    { 'key': '0600', 'value': '06:00 AM' },
    { 'key': '0630', 'value': '06:30 AM' },
    { 'key': '0700', 'value': '07:00 AM' },
    { 'key': '0730', 'value': '07:30 AM' },
    { 'key': '0800', 'value': '08:00 AM' },
    { 'key': '0830', 'value': '08:30 AM' },
    { 'key': '0900', 'value': '09:00 AM' },
    { 'key': '0930', 'value': '09:30 AM' },
    { 'key': '1000', 'value': '10:00 AM' },
    { 'key': '1030', 'value': '10:30 AM' },
    { 'key': '1100', 'value': '11:00 AM' },
    { 'key': '1130', 'value': '11:30 AM' },
    { 'key': '1200', 'value': '12:00 PM' },
    { 'key': '1230', 'value': '12:30 PM' },
    { 'key': '1300', 'value': '01:00 PM' },
    { 'key': '1330', 'value': '01:30 PM' },
    { 'key': '1400', 'value': '02:00 PM' },
    { 'key': '1430', 'value': '02:30 PM' },
    { 'key': '1500', 'value': '03:00 PM' },
    { 'key': '1530', 'value': '03:30 PM' },
    { 'key': '1600', 'value': '04:00 PM' },
    { 'key': '1630', 'value': '04:30 PM' },
    { 'key': '1700', 'value': '05:00 PM' },
    { 'key': '1730', 'value': '05:30 PM' },
    { 'key': '1800', 'value': '06:00 PM' },
    { 'key': '1830', 'value': '06:30 PM' },
    { 'key': '1900', 'value': '07:00 PM' },
    { 'key': '1930', 'value': '07:30 PM' },
    { 'key': '2000', 'value': '08:00 PM' },
    { 'key': '2030', 'value': '08:30 PM' },
    { 'key': '2100', 'value': '09:00 PM' },
    { 'key': '2130', 'value': '09:30 PM' },
    { 'key': '2200', 'value': '10:00 PM' },
    { 'key': '2230', 'value': '10:30 PM' },
    { 'key': '2300', 'value': '11:00 PM' },
    { 'key': '2330', 'value': '11:30 PM' }
  ];

  bhModel: BusinessHoursModel;
  holidays: HolidayModel[];
  showPastHolidays: boolean = false;

  public sub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private confirmationDialogService: ConfirmationDialogService,
    private holidayDialogService: HolidayDialogService,
    public authService: AuthService,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.initBHDefaultValues();
    this.loadBusinessHours();
    //this.loadHolidaysTotal();
  }

  ngOnInit() {


    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.page = +params['page'] || 1;

        console.log('ngOnInit page change');
        if (!this.collectionSize) {
          this.loadHolidaysTotal(false);
        }
      });
  }

  initBHDefaultValues() {
    this.bhModel = new BusinessHoursModel();
    this.bhModel.offWorkMessage = 'Sorry - live-agent business hours have now passed. Your text will be addressed first thing tomorrow at [xxx]';
    this.bhModel.days = [];
    this.bhModel.days.push({ day: 0, dayLongName: 'Sunday', dayShortName: 'Sun', isWorkDay: false, startTime: '0800', endTime: '1700' });
    this.bhModel.days.push({ day: 1, dayLongName: 'Monday', dayShortName: 'Mon', isWorkDay: true, startTime: '0800', endTime: '1700' });
    this.bhModel.days.push({ day: 2, dayLongName: 'Tuesday', dayShortName: 'Tue', isWorkDay: true, startTime: '0800', endTime: '1700' });
    this.bhModel.days.push({ day: 3, dayLongName: 'Wednesday', dayShortName: 'Wed', isWorkDay: true, startTime: '0800', endTime: '1700' });
    this.bhModel.days.push({ day: 4, dayLongName: 'Thursday', dayShortName: 'Thu', isWorkDay: true, startTime: '0800', endTime: '1700' });
    this.bhModel.days.push({ day: 5, dayLongName: 'Friday', dayShortName: 'Fri', isWorkDay: true, startTime: '0800', endTime: '1700' });
    this.bhModel.days.push({ day: 6, dayLongName: 'Saturday', dayShortName: 'Sat', isWorkDay: false, startTime: '0800', endTime: '1700' });

    //this.holidays = [];
  }

  loadBusinessHours() {
    this.blockUI.start();
    this.appService.getBusinessHours().subscribe(
      data => {
        this.bhModel = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      });
  }

  loadHolidaysTotal(block) {
    if (block) {
      this.blockUI.start();
    }
    this.appService.getHolidaysTotal(this.showPastHolidays).subscribe(
      data => {
        this.collectionSize = <number>data.property;

        this.blockUI.stop();
        this.loadHolidays(true);
      },
      error => {
        this.showResponseError(error);
      }
    );
  }

  loadHolidays(block) {
    if (block) {
      this.blockUI.start();
    }

    this.appService.getHolidays(this.showPastHolidays, (this.page - 1) * this.pageSize, this.pageSize).subscribe(
      data => {
        this.holidays = data;

        if (block) {
          this.blockUI.stop();
        }
      },
      error => {
        this.showError('Error in loading holidays');
        this.showResponseError(error);
      });
  }

  ngOnDestroy() {
  }

  restoreDefaults() {
    this.confirmationDialogService.confirm('Confirmation', 'Are you sure you want to restore to default settings?', 'Yes', 'No')
      .then((confirmed: boolean) => {
        if (confirmed) {
          this.initBHDefaultValues();
        }
      })
      .catch(() => { });
  }

  save() {
    this.blockUI.start();
    this.appService.updateBusinessHours(this.bhModel).subscribe(
      data => {
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
  }

  editHoliday(holiday: HolidayModel) {
    if (holiday == null) {
      holiday = new HolidayModel();
    }

    console.log(holiday);
    this.holidayDialogService.getDialog(holiday, 'lg')
      .then((data) => {
        this.blockUI.start();
        this.appService.updateHoliday(data).subscribe(
          data => {
            this.blockUI.stop();
            this.loadHolidays(true);
          },
          error => {
            this.showResponseError(error);
          }
        );
      })
      .catch(() => { });
  }

  deleteHoliday(holiday: HolidayModel) {

    this.confirmationDialogService.confirm('Confirmation', 'Are you sure you want to delete this holiday?', 'Yes', 'No')
      .then((confirmed: boolean) => {
        if (confirmed) {
          this.blockUI.start();
          this.appService.deleteHoliday(holiday).subscribe(
            data => {
              this.blockUI.stop();
              this.loadHolidays(true);
            },
            error => {
              this.showResponseError(error);
            }
          );
        }
      })
      .catch(() => { });
  }

  notLessToday(d: any) {
    var today = new Date(new Date().toISOString().slice(0, 10));
    return new Date(d) >= today;
  }

  pageChange() {
    console.log('pageChange ' + this.page);

    this.router.navigate(['manage-business-hours'], { queryParams: { page: this.page } });

    this.loadHolidays(true);
  }

  showPastHolidaysChange() {
    this.showPastHolidays = !this.showPastHolidays;
    this.collectionSize = undefined;
    if (this.page !== 1) {
      this.page = 1;
      this.router.navigate(['manage-business-hours'], { queryParams: { page: this.page } });
    }
    else {
      this.loadHolidaysTotal(true);
    }
  }
}
