export class TemplateGroupModel {
  id: number;
  name: string;
}

export class TemplateTypeModel {
  id: number;
  name: string;
  allowedReplacements: string;
  requiredReplacements: string;
  templateGroupId: number;
}

export class TemplateModel {
  id: number;
  templateName: string;
  templateBody: string;
  templateTypeId: number;
}

export class UploadExcelTemplateModel {
  id: number;
  type: string;
  template: string;
  phoneColumnName: string;
}
