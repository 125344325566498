import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { SnotifyService } from 'ng-snotify';
import { Router } from '@angular/router';
import { EnvService } from '../../services/env.service';

@Component({
  selector: 'manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent extends BaseComponent {

  constructor(
    private router: Router,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);
  }

  ngOnDestroy() {
  }

  navigate(url) {
    this.router.navigate(['/' + url], {});
  }
}
