import { Input, Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConversationModel } from "../../../../app/models/conversationModel";

@Component({
  selector: 'update-conversation-dialog-component',
  templateUrl: './update-conversation-dialog.component.html',
  styleUrls: ['./update-conversation-dialog.component.css']
})
export class UpdateConversationDialogComponent implements OnInit {
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() conversation: ConversationModel;

  constructor(public activeModal: NgbActiveModal,) { }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.dismiss();
  }

  public accept() {
    this.activeModal.close(this.conversation);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
