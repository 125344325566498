import { Component } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../../../services/authService';
import { AppService } from '../../../../app/services/appService';
import { ConfirmationDialogService } from '../../dialogs/confirmation-dialog/confirmation-dialog.service';
import { UploadExcelService } from '../../../../app/services/uploadExcelService';
import { UploadExcelTemplateModel } from '../../../../app/models/templateModels';
import { UploadExcelTemplateDialogService } from '../../dialogs/upload-excel-template-dialog/upload-excel-template-dialog.service';
import { EnvService } from '../../../services/env.service';

@Component({
  selector: 'manage-uploads',
  templateUrl: './manage-uploads.component.html',
  styleUrls: ['./manage-uploads.component.css']
})
export class ManageUploadsComponent extends BaseComponent {

  fileToUpload: File = null;
  uploadTemplate: string;
  uploadPreview: string;

  public selectedTemplate: UploadExcelTemplateModel;
  templates: UploadExcelTemplateModel[];

  constructor(
    snotifyService: SnotifyService,
    private appService: AppService,
    private uploadExcelService: UploadExcelService,
    public authService: AuthService,
    private confirmationDialogService: ConfirmationDialogService,
    private uploadExcelTemplateDialogService: UploadExcelTemplateDialogService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.loadUploadTemplate();
  }

  ngOnDestroy() {
  }

  loadUploadTemplate() {
    this.blockUI.start();

    this.uploadExcelService.getUploadExcelTemplates().subscribe(
      data => {
        this.templates = data;

        this.selectedTemplate = this.templates[0];
        this.onChangeObj(this.selectedTemplate);
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
  }

  onChangeObj(newObj: UploadExcelTemplateModel) {
    if (newObj) {
      this.uploadTemplate = newObj.template;
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  saveTemplate(isNew) {
    /*this.blockUI.start();
    this.appService.updateUploadTemplate(this.uploadTemplate).subscribe(
      data => {
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );*/

    var tmpl = {
      id: 0,
      type: '',
      template: this.uploadTemplate,
      phoneColumnName: ''
    }

    if (isNew === 0) {
      tmpl = { ... this.selectedTemplate };
      tmpl.template = this.uploadTemplate;
    }

    this.uploadExcelTemplateDialogService.getDialog(tmpl, 'lg').then((obj) => {
      if (obj != null) {
        this.blockUI.start();

        var updItem = { ...obj };
        console.log(updItem);

        this.uploadExcelService.postTemplate(updItem)
          .subscribe(
            data => {
              if (isNew === 0) {
                var arrItem = this.templates.filter((f) => f.id === updItem.id)[0];
                arrItem.template = updItem.template;
                arrItem.type = updItem.type;
                arrItem.phoneColumnName = updItem.phoneColumnName;
                this.selectedTemplate = arrItem;
                this.uploadTemplate = arrItem.template;
              }
              else {
                this.templates.push(data);
                this.selectedTemplate = this.templates[this.templates.length - 1];
                this.uploadTemplate = this.selectedTemplate.template;
              }

              this.blockUI.stop();
            },
            error => {
              this.showResponseError(error);
            }
          );
      }
    }).catch(() => { });
  }

  sendMessages() {
    this.confirmationDialogService.confirm('Confirmation', 'Are you sure you want to send messages?')
      .then((confirmed) => {
        var formData: FormData = new FormData();
        if (this.fileToUpload) {
          formData.append("File", this.fileToUpload, this.fileToUpload.name);
        }
        formData.append("Template", this.uploadTemplate);
        formData.append("TemplateId", this.selectedTemplate.id.toString());

        this.blockUI.start();
        this.appService.uploadExcelSend(formData).subscribe(
          data => {
            //this.uploadPreview = data.property;
            this.blockUI.stop();
            this.showSuccess(data.property);
          },
          error => {
            this.showResponseError(error);
          }
        );
      })
      .catch(() => { });
  }


  updatePreview() {
    var formData: FormData = new FormData();
    if (this.fileToUpload) {
      formData.append("File", this.fileToUpload, this.fileToUpload.name);
    }
    formData.append("Template", this.uploadTemplate);
    formData.append("TemplateId", this.selectedTemplate.id.toString());

    this.blockUI.start();
    this.appService.generateUploadPreview(formData).subscribe(
      data => {
        this.uploadPreview = data.property;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      }
    );
  }
}
