import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewConversationDialogComponent } from './new-conversation-dialog.component';
import { ConversationModel } from '../../../../app/models/conversationModel';

@Injectable()
export class NewConversationDialogService {

  constructor(private modalService: NgbModal) { }

  public getPhoneNumber(
    /*btnOkText: string = 'Create',
    btnCancelText: string = 'Cancel',*/
    conversation: ConversationModel,
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<ConversationModel> {
    const modalRef = this.modalService.open(NewConversationDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.btnOkText = 'Create';
    modalRef.componentInstance.btnCancelText = 'Cancel';
    modalRef.componentInstance.conversation = { ...conversation };

    return modalRef.result;
  }
}
