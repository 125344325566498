import { Input, Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AuthService } from "../../../services/authService";
import { TemplateModel, TemplateTypeModel } from "../../../models/templateModels";
import { TemplatesService } from "../../../services/templatesService";

@Component({
  selector: 'template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.css']
})
export class TemplateDialogComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() template: TemplateModel;
  @Input() templateType: TemplateTypeModel;

  constructor(public activeModal: NgbActiveModal, private templatesService: TemplatesService, public authService: AuthService) {
  }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.dismiss();
  }

  public accept() {
    this.activeModal.close(this.template);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
