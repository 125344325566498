import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { TokenModel } from '../../../models/tokenModel';
import { AuthService } from '../../../services/authService';
import { EnvService } from '../../../services/env.service';
import { TokensService } from '../../../services/tokensService';
import { BaseComponent } from '../../base/base.component';
import { ConfirmationDialogService } from '../../dialogs/confirmation-dialog/confirmation-dialog.service';
import { TokenDialogService } from '../../dialogs/token-dialog/token-dialog.service';

@Component({
  selector: 'app-manage-tokens',
  templateUrl: './manage-tokens.component.html',
  styleUrls: ['./manage-tokens.component.css']
})
export class ManageTokensComponent extends BaseComponent {

  public tokens: TokenModel[];

  constructor(
    private tokensService: TokensService,
    private confirmationDialogService: ConfirmationDialogService,
    private tokenDialog: TokenDialogService,
    snotifyService: SnotifyService,
    public authService: AuthService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.loadTokens();
  }

  ngOnDestroy() {
  }

  loadTokens() {
    this.blockUI.start();
    this.tokensService.getTokens().subscribe(
      data => {
        this.tokens = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      });
  }

  editToken(item: TokenModel) {
    if (item == null) {
      item = new TokenModel();
      item.id = 0;
    }

    this.tokenDialog.getDialog(item, 'lg').then((data) => {
      if (data != null) {
        this.blockUI.start();
    
        if (data.id === 0) {
          this.tokensService.postToken(data)
            .subscribe(
              data => {
                this.tokens.push(data);
    
                this.blockUI.stop();
              },
              error => {
                this.showResponseError(error);
              });
        }
        else {
          this.tokensService.putToken(data)
            .subscribe(
              data => {
                var arrItem = this.tokens.filter((f) => f.id === data.id)[0];
                arrItem.accessToken = data.accessToken;
                arrItem.expires = data.expires;
                arrItem.tokenName = data.tokenName;
    
                this.blockUI.stop();
              },
              error => {
                this.showResponseError(error);
              }
            );
        }
      }
    }).catch(() => { });
  }

  deleteToken(item: TokenModel) {
    this.confirmationDialogService.confirm('Confirmation', 'Are you sure you want to delete token ' + item.tokenName + '?')
      .then((confirmed: boolean) => {
        if (confirmed) {
          this.blockUI.start();
          this.tokensService.deleteToken(item.id)
            .subscribe(
              data => {
                console.log(data);
                this.loadTokens();
              },
              error => {
                this.showResponseError(error);
              });
        }
      })
      .catch(() => { });
  }
}
