import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConversationStatusesEnum } from '../models/enums';
import { ConversationModel } from '../models/conversationModel';
import { BaseService } from './baseService';
import { HttpClient } from '@angular/common/http';
import { ConversationUpdateModel } from '../models/conversationUpdateModel';
import { ConversationTotalModel } from '../models/ConversationTotalModel';
import { EnvService } from './env.service';


@Injectable()
export class ConversationsService extends BaseService {

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  getConversationsCount(state: number, searchTerm: string, readUnreadFilter: string, selectedAgent: string, selectedMesageDate: string, eventName: string): Observable<ConversationTotalModel> {
    return this
      .http
      .get<ConversationTotalModel>(this.ApiUrl + 'api/conversations/conversationscount?state=' + state + '&searchTerm=' + searchTerm + '&readUnreadFilter=' + readUnreadFilter + '&agent=' + selectedAgent + '&mdate=' + selectedMesageDate + '&eventName=' + eventName);
  }

  getConversations(status: ConversationStatusesEnum, skip: number, take: number, searchTerm: string, orderByField: string, orderDir: string, readUnreadFilter: string, selectedMesageDate: string, eventName: string): Observable<ConversationModel[]> {
    return this
      .http
      .get<ConversationModel[]>(this.ApiUrl + 'api/conversations/myconversations?status=' + status + '&skip=' + skip + '&take=' + take + '&searchTerm=' + searchTerm + '&orderByField=' + orderByField + '&orderDir=' + orderDir + '&readUnreadFilter=' + readUnreadFilter + '&mdate=' + selectedMesageDate + '&eventName=' + eventName);
  }

  getAllConversations(status: ConversationStatusesEnum, skip: number, take: number, searchTerm: string, orderByField: string, orderDir: string, readUnreadFilter: string, selectedAgent: string, selectedMesageDate: string, eventName: string): Observable<ConversationModel[]> {
    return this
      .http
      .get<ConversationModel[]>(this.ApiUrl + 'api/conversations/allconversations?status=' + status + '&skip=' + skip + '&take=' + take + '&searchTerm=' + searchTerm + '&orderByField=' + orderByField + '&orderDir=' + orderDir + '&readUnreadFilter=' + readUnreadFilter + '&agent=' + selectedAgent + '&mdate=' + selectedMesageDate + '&eventName=' + eventName);
  }

  getUnassignedConversations(skip: number, take: number, searchTerm: string, orderByField: string, orderDir: string, readUnreadFilter: string, selectedMesageDate: string, eventName: string): Observable<ConversationModel[]> {
    return this
      .http
      .get<ConversationModel[]>(this.ApiUrl + 'api/conversations/unassignedconversations?skip=' + skip + '&take=' + take + '&searchTerm=' + searchTerm + '&orderByField=' + orderByField + '&orderDir=' + orderDir + '&readUnreadFilter=' + readUnreadFilter + '&mdate=' + selectedMesageDate + '&eventName=' + eventName);
  }

  getDisconnectedConversations(skip: number, take: number, searchTerm: string, orderByField: string, orderDir: string, readUnreadFilter: string, selectedMesageDate: string, eventName: string): Observable<ConversationModel[]> {
    return this
      .http
      .get<ConversationModel[]>(this.ApiUrl + 'api/conversations/disconnectedconversations?skip=' + skip + '&take=' + take + '&searchTerm=' + searchTerm + '&orderByField=' + orderByField + '&orderDir=' + orderDir + '&readUnreadFilter=' + readUnreadFilter + '&mdate=' + selectedMesageDate + '&eventName=' + eventName);
  }

  getBasicInfo(conversationId: number) {
    return this
      .http
      .get<ConversationModel>(this.ApiUrl + 'api/conversations/basicInfo?conversationId=' + conversationId);
  }

  markCompleted(conversationId: number) {
    return this
      .http
      .post<ConversationModel>(this.ApiUrl + 'api/conversations/markcompleted', conversationId);
  }

  markCompletedList(conversationIds: number[]) {
    return this
      .http
      .post<ConversationModel>(this.ApiUrl + 'api/conversations/markcompletedlist', conversationIds);
  }

  markReadUnreadList(conversationIds: number[], read: boolean) {
    return this
      .http
      .post<ConversationModel>(this.ApiUrl + 'api/conversations/markreadunreadlist', {
        conversationIds,
        read
      });
  }

  markOpen(conversationId: number) {
    return this
      .http
      .post<ConversationModel>(this.ApiUrl + 'api/conversations/markopen', conversationId);
  }

  newConversation(c: ConversationModel) {
    c.status = ConversationStatusesEnum.Open;
    return this
      .http
      .post<ConversationModel>(this.ApiUrl + 'api/conversations/NewConversation', c);
  }

  updateConversation(c: ConversationModel) {
    return this
      .http
      .post<ConversationModel>(this.ApiUrl + 'api/conversations/UpdateConversation', c);
  }

  checkConversationUpdates(skip: number, take: number, conversations: number[], view: number, searchTerm: string, orderByField: string, orderDir: string, latestConversationId: number) {
    return this
      .http
      .post<ConversationUpdateModel>(this.ApiUrl + 'api/conversations/CheckConversationUpdates', {
        'skip': skip,
        'take': take,
        'conversations': conversations,
        'view': view,
        'searchTerm': searchTerm,
        'orderByField': orderByField,
        'orderDir': orderDir,
        'latestConversationId': latestConversationId
      });
  }

  getEvents(): Observable<string[]> {
    return this
      .http
      .get<string[]>(this.ApiUrl + 'api/conversations/events');
  }
}
