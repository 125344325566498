import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignAgentDialogComponent } from './assign-agent-dialog.component';
import { AgentModel } from '../../../models/agentModel';

@Injectable()
export class AssignAgentDialogService {

  constructor(private modalService: NgbModal) { }

  public getAgent(
    /*btnOkText: string = 'Create',
    btnCancelText: string = 'Cancel',*/
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<AgentModel> {
    const modalRef = this.modalService.open(AssignAgentDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.btnOkText = 'Assign';
    modalRef.componentInstance.btnCancelText = 'Cancel';

    return modalRef.result;
  }
}
