import { SnotifyService } from "ng-snotify";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { HttpErrorResponse } from "@angular/common/http";

export class BaseComponent {
  @BlockUI() blockUI: NgBlockUI;

  public page: number;
  public pageSize: number = 10;
  public collectionSize: number;

  public BaseApiUrl: string = '';
  constructor(private snotifyService: SnotifyService,
    baseApiUrl: string) {
    this.BaseApiUrl = baseApiUrl;
  }

  public showError(message: string) {
    this.blockUI.stop();
    this.snotifyService.error(message, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      position: 'rightTop'
    });
  }
  public showResponseError(error: HttpErrorResponse) {
    this.blockUI.stop();
    var errorMsg = '';
    if (error.status == 401) {
      errorMsg = error.statusText;
    }
    else {
      errorMsg = error.error;
    }
    this.snotifyService.error('Error occurred: ' + errorMsg, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      position: 'rightTop'
    });
  }
  public showInfo(message: string) {
    this.snotifyService.info(message, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      position: 'rightTop'
    });
  }
  public showSuccess(message: string) {
    this.snotifyService.success(message, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      position: 'rightTop'
    });
  }
}
