export class StoreTimesModel {
  isWorkDay: boolean = true;
  day: number;
  dayShortName: string;
  dayLongName: string;
  startTime: string;
  endTime: string;
}

export class HolidayModel {
  id: number;
  date: Date;
  offWorkMessage: string;
}

export class BusinessHoursModel {
  days: StoreTimesModel[];
  offWorkMessage: string;
}
