import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';
import { HttpClient } from '@angular/common/http';
import { TemplateGroupModel, TemplateTypeModel, TemplateModel } from '../models/templateModels';
import { EnvService } from './env.service';


@Injectable()
export class TemplatesService extends BaseService {

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  getTemplateGroups(): Observable<TemplateGroupModel[]> {
    return this
      .http
      .get<TemplateGroupModel[]>(this.ApiUrl + 'api/Templates/gettemplategroups');
  }

  getTemplateTypes(templateGroupId: number): Observable<TemplateTypeModel[]> {
    return this
      .http
      .get<TemplateTypeModel[]>(this.ApiUrl + 'api/Templates/gettemplatetypes?templateGroupId=' + templateGroupId);
  }

  getTemplates(templateTypeId: number): Observable<TemplateModel[]> {
    return this
      .http
      .get<TemplateModel[]>(this.ApiUrl + 'api/Templates/gettemplates?templateTypeId=' + templateTypeId);
  }

  postTemplate(template: TemplateModel): Observable<TemplateModel> {
    return this
      .http
      .post<TemplateModel>(this.ApiUrl + 'api/Templates', template);
  }

  putTemplate(template: TemplateModel): Observable<TemplateModel> {
    return this
      .http
      .put<TemplateModel>(this.ApiUrl + 'api/Templates/' + template.id, template);
  }

  deleteTemplate(id: number): Observable<any> {
    return this
      .http
      .delete(this.ApiUrl + 'api/Templates/' + id);
  }
}
