import { Input, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SnotifyService } from 'ng-snotify';
import { TokenModel } from '../../../models/tokenModel';
import { AuthService } from '../../../services/authService';
import { EnvService } from '../../../services/env.service';
import { TokensService } from '../../../services/tokensService';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-token-dialog',
  templateUrl: './token-dialog.component.html',
  styleUrls: ['./token-dialog.component.css']
})
export class TokenDialogComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() token: TokenModel;
  public tokens: TokenModel[];

  constructor(
    public activeModal: NgbActiveModal,
    private tokensService: TokensService,
    public authService: AuthService,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);
  }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.dismiss();
  }

  public accept() {
    this.activeModal.close(this.token);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
