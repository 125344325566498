import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadExcelTemplateModel } from '../../../models/templateModels';
import { UploadExcelTemplateDialogComponent } from './upload-excel-template-dialog.component';

@Injectable()
export class UploadExcelTemplateDialogService {

  constructor(private modalService: NgbModal) { }

  public getDialog(
    /*btnOkText: string = 'Create',
    btnCancelText: string = 'Cancel',*/
    template: UploadExcelTemplateModel,
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<UploadExcelTemplateModel> {
    const modalRef = this.modalService.open(UploadExcelTemplateDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.btnOkText = 'Save';
    modalRef.componentInstance.btnCancelText = 'Cancel';
    modalRef.componentInstance.template = { ...template };

    return modalRef.result;
  }
}
