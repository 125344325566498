import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './authService';
import { Observable } from 'rxjs';


@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    console.log('AuthGuardService:canActivate');

    return this.auth.isAuthenticated(() => { this.router.navigate(['/login']); });
  }
}
