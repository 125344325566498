import { Input, Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AuthService } from "../../../services/authService";
import { PhoneNumberModel } from "../../../models/phoneNumberModel";
import { PhoneNumbersService } from "../../../services/phoneNumbersService";
import { BaseComponent } from "../../base/base.component";
import { SnotifyService } from "ng-snotify";
import { EnvService } from "../../../services/env.service";

@Component({
  selector: 'phone-number-dialog',
  templateUrl: './phone-number-dialog.component.html',
  styleUrls: ['./phone-number-dialog.component.css']
})
export class PhoneNumberDialogComponent extends BaseComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() phoneNumber: PhoneNumberModel;
  public phoneNumbers: PhoneNumberModel[];

  constructor(
    public activeModal: NgbActiveModal,
    private phoneNumbersService: PhoneNumbersService,
    public authService: AuthService,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);
  }

  ngOnInit() {
    this.blockUI.start();
    this.phoneNumbersService.getPhoneNumbers().subscribe(
      data => {
        this.phoneNumbers = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      });
  }

  public decline() {
    this.activeModal.dismiss();
  }

  public accept() {
    this.activeModal.close(this.phoneNumber);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
