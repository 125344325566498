import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './baseService';
import { MessageModel } from '../models/messageModel';
import { NewMessageModel } from '../models/newMessageModel';
import { HttpRequest, HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';


@Injectable()
export class MessagesService extends BaseService {

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) {
    super();

    this.ApiUrl = env.apiUrl;
  }

  getMessages(conversationId: number, skip: number, take: number, searchTerm: string): Observable<MessageModel[]> {
    return this
      .http
      .get<MessageModel[]>(this.ApiUrl + 'api/messages/messages?conversationId=' + conversationId + '&skip=' + skip + '&take=' + take + '&searchTerm=' + searchTerm);
  }

  sendTextMessage(message: NewMessageModel) {
    return this
      .http
      .post(this.ApiUrl + 'api/messages/sendtextmessage', message);
  }

  sendPictureMessage(formData: FormData) {
    const uploadReq = new HttpRequest('POST', `api/messages/SendPictureMessage`, formData, {
      reportProgress: true,
    });

    return this.http.request(uploadReq);
  }

  checkMessagesUpdates(conversationId: number, searchTerm: string): Observable<boolean> {
    return this
      .http
      .get<boolean>(this.ApiUrl + 'api/messages/CheckMessagesUpdates?conversationId=' + conversationId + '&searchTerm=' + searchTerm);
  }

  getMessagesUpdates(conversationId: number, searchTerm: string): Observable<MessageModel[]> {
    return this
      .http
      .get<MessageModel[]>(this.ApiUrl + 'api/messages/GetMessagesUpdates?conversationId=' + conversationId + '&searchTerm=' + searchTerm);
  }

}
