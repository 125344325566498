import { Input, Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AuthService } from "../../../services/authService";
import { UploadExcelTemplateModel } from "../../../models/templateModels";

@Component({
  selector: 'upload-excel-template-dialog',
  templateUrl: './upload-excel-template-dialog.component.html',
  styleUrls: ['./upload-excel-template-dialog.component.css']
})
export class UploadExcelTemplateDialogComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() template: UploadExcelTemplateModel;

  constructor(public activeModal: NgbActiveModal, public authService: AuthService) {
  }

  ngOnInit() {
    if (!this.template.phoneColumnName) {
      this.template.phoneColumnName = "Phone";
    }
  }

  public decline() {
    this.activeModal.dismiss();
  }

  public accept() {
    this.activeModal.close(this.template);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
