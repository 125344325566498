import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhoneNumberDialogComponent } from './phone-number-dialog.component';
import { PhoneNumberModel } from '../../../models/phoneNumberModel';

@Injectable()
export class PhoneNumberDialogService {

  constructor(private modalService: NgbModal) { }

  public getDialog(
    /*btnOkText: string = 'Create',
    btnCancelText: string = 'Cancel',*/
    phoneNumber: PhoneNumberModel,
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<PhoneNumberModel> {
    const modalRef = this.modalService.open(PhoneNumberDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.btnOkText = 'Save';
    modalRef.componentInstance.btnCancelText = 'Cancel';
    modalRef.componentInstance.phoneNumber = { ...phoneNumber };

    return modalRef.result;
  }
}
