import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConversationModel } from '../../../../app/models/conversationModel';
import { UpdateConversationDialogComponent } from './update-conversation-dialog.component';

@Injectable()
export class UpdateConversationDialogService {

  constructor(private modalService: NgbModal) { }

  public getConversation(
    /*btnOkText: string = 'Create',
    btnCancelText: string = 'Cancel',*/
    conversation: ConversationModel,
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<ConversationModel> {
    const modalRef = this.modalService.open(UpdateConversationDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.btnOkText = 'Update';
    modalRef.componentInstance.btnCancelText = 'Cancel';
    modalRef.componentInstance.conversation = { ...conversation };

    return modalRef.result;
  }
}
