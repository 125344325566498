import { Component } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { BaseComponent } from '../../base/base.component';
import { AgentModel } from '../../../models/agentModel';
import { AgentsService } from '../../../services/agentsService';
import { ConfirmationDialogService } from '../../dialogs/confirmation-dialog/confirmation-dialog.service';
import { EditAgentPhoneDialogService } from '../../dialogs/edit-agent-phone-dialog/edit-agent-phone-dialog.service';
import { PhoneNumberModel } from '../../../models/phoneNumberModel';
import { AuthService } from '../../../services/authService';
import { EnvService } from '../../../services/env.service';

@Component({
  selector: 'manage-agents',
  templateUrl: './manage-agents.component.html',
  styleUrls: ['./manage-agents.component.css']
})
export class ManageAgentsComponent extends BaseComponent {

  public agents: AgentModel[];
  public showInactive: boolean = false;

  constructor(
    private agentsService: AgentsService,
    private confirmationDialogService: ConfirmationDialogService,
    private editAgentPhoneDialogService: EditAgentPhoneDialogService,
    public authService: AuthService,
    snotifyService: SnotifyService,
    private env: EnvService) {
    super(snotifyService, env.apiUrl);

    this.loadAgents();
  }

  ngOnDestroy() {
  }

  loadAgents() {
    this.blockUI.start();

    this.agentsService.getAgents(this.showInactive).subscribe(
      data => {
        this.agents = data;
        this.blockUI.stop();
      },
      error => {
        this.showResponseError(error);
      });
  }

  changeInactive() {
    this.showInactive = !this.showInactive;
    this.loadAgents();
  }

  changeAgentStatus(item: AgentModel, value: boolean) {
    const msg = 'Are you sure you want to ' + (value ? 'Activate' : 'Decativate') + ' selected agent?';
    this.confirmationDialogService.confirm('Confirmation', msg).then((res) => {
      console.log(res);
      this.blockUI.start();
      this.agentsService.changeAgentStatus(item.id, value).subscribe(
        () => {
          this.blockUI.stop();
          this.loadAgents();
        },
        error => {
          this.showResponseError(error);
        }
      );
    });
  }

  editAgent(item: AgentModel) {

    var pn = new PhoneNumberModel();
    pn.id = item.phoneNumberId;
    pn.phoneNumber = item.phoneNumber;

    this.editAgentPhoneDialogService.getDialog(pn, item.icmUserName, 'lg').then((obj) => {
      if (obj != null) {
        this.blockUI.start();

        var updItem = { ...item };
        if (obj.phoneNumber != null) {
          updItem.phoneNumberId = obj.phoneNumber.id;
          updItem.phoneNumber = obj.phoneNumber.phoneNumber;
        }
        updItem.icmUserName = obj.icmUsername;

        console.log(updItem);

        this.agentsService.updateAgentPhone(updItem)
          .subscribe(
            data => {
              var arrItem = this.agents.filter((f) => f.id === updItem.id)[0];
              arrItem.phoneNumber = updItem.phoneNumber;
              arrItem.phoneNumberId = updItem.phoneNumberId;
              arrItem.icmUserName = updItem.icmUserName;

              this.blockUI.stop();
            },
            error => {
              this.showResponseError(error);
            }
          );
      }
    }).catch(() => { });
  }

  unAssignPhone(item: AgentModel) {
    this.confirmationDialogService.confirm('Confirmation', 'Are you sure you want to unassign this phone number from agent?', 'Yes', 'No')
      .then((confirmed: boolean) => {
        if (confirmed) {
          this.blockUI.start();
          this.agentsService.unAssignPhone(item)
            .subscribe(
              data => {
                var a = this.agents.filter((a) => a.id == item.id)[0];
                a.phoneNumber = '';
                delete a.phoneNumberId;
                this.blockUI.stop();
              },
              error => {
                this.showResponseError(error);
              }
            );
        }
      })
      .catch(() => { });
  }
}
