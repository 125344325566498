export enum MessageQueueStatusesEnum {
  New,
  queued,
  failed,
  sending,
  sent,
  delivered,
  undelivered
}

export enum ConversationStatusesEnum {
  Open,
  Completed
}

export enum DirectionsEnum {
  Inbound,
  Outbound
}
